<template>
  <el-dialog id="dynamicDialog" :visible.sync="visible" :title="dialogTitle" width="428px">
    <div slot="title">
      <slot name="header">
        <img src="@/assets/uploads/close.png" class="closeImg" alt @click="onClose()" />
      </slot>
      <div class="title_slot"></div>
    </div>
    <div class="dialog_body">
      <span>{{ dialogDesc }}</span>
    </div>
    <div slot="footer" class="btn_submit">
      <el-button class="white_button" @click="onClose">
        {{ $t('subIb.cancel') }}
      </el-button>
      <el-button class="purple_button" @click="submitAcc">
        {{ $t('subIb.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    dialogDesc: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    dialogVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateVisible(bool);
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    updateVisible(bool) {
      this.$emit('update:dialogVisible', bool);
    },
    submitAcc() {
      this.$emit('submit');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/settings.scss';
</style>
