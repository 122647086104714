<template>
  <div id="accountDialog">
    <el-dialog :visible.sync="visible" @close="onCancel" :close-on-click-modal="true" destroy-on-close width="90%">
      <div slot="title">
        <slot name="header">
          <h4 class="main_title">{{ existingData ? $t('subIb.editAccount') : $t('subIb.subCreateIbAcc') }}</h4>
          <img src="@/assets/uploads/close.png" class="closeImg" alt @click="onCancel" />
        </slot>
        <div class="title_slot"></div>
      </div>

      <div class="dialog_body">
        <el-form :model="accForm" ref="accForm" :rules="formRules" autocomplete="off" label-position="top">
          <el-form-item prop="email" :label="$t('subIb.emailAddress')">
            <el-input v-model="accForm.email" :maxLength="80" clearable />
          </el-form-item>
          <el-form-item prop="password" class="password" v-if="!existingData">
            <template slot="label">
              <div class="label_class">
                <div>{{ $t('subIb.password') }}</div>

                <el-tooltip placement="top" popperClass="popper" :offset="100">
                  <div slot="content" class="tooltip_body" v-html="$t('subIb.passwordTooltip')"></div>
                  <i class="el-icon-warning"></i>
                </el-tooltip>
              </div>
            </template>
            <el-input v-model="accForm.password" show-password autocomplete="new-password" clearable />
          </el-form-item>

          <el-form-item prop="permission">
            <template slot="label">
              <div class="label_class">
                <div>{{ $t('subIb.permissionField') }}</div>
                <el-tooltip placement="top" popperClass="popper" :offset="100">
                  <div slot="content" class="tooltip_body" v-html="$t('subIb.permissionTooltip')"></div>
                  <i class="el-icon-warning"></i>
                </el-tooltip>
              </div>
            </template>

            <el-checkbox-group v-model="accForm.permission" @change="fundsPermissionOnChange">
              <el-checkbox-group v-model="accForm.permission">
                <el-checkbox class="custom_checkbox mb-4" :label="1">{{ $t('subIb.clientData') }}</el-checkbox>
                <el-checkbox class="custom_checkbox mb-4" :label="2">{{ $t('subIb.ibData') }}</el-checkbox>
                <el-checkbox class="custom_checkbox mb-4" :label="3" @change="fundsPermissionOnChange">{{
                  $t('subIb.fundsPermission')
                }}</el-checkbox>
              </el-checkbox-group>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item class="remark" :label="$t('subIb.remark')" prop="remark"
            ><el-input
              :placeholder="$t('subIb.remarkPlaceholder')"
              maxlength="100"
              v-model="accForm.remark"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item class="status_dynamic" :label="$t('subIb.status')" prop="subStatus">
            <el-switch
              active-color="#41ba92"
              :active-text="accForm.subStatus ? $t('subIb.isActive') : $t('subIb.isInactive')"
              v-model="accForm.subStatus"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="btn_submit">
        <el-button class="white_button" @click="onCancel">
          {{ $t('subIb.cancel') }}
        </el-button>
        <el-button class="purple_button" @click="submitAcc">
          {{ $t('subIb.confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <DynamicDialog
      :dialogDesc="$t('subIb.updateWithdrawalDesc')"
      dialogTitle=""
      :dialogVisible.sync="dialogVisible"
      @submit="submitDialog"
      @close="closeDialog"
    />
  </div>
</template>
<script>
import { mockPermissionList, defaultVariableMapping, getAccountResponse, updateAccountRequest } from './SubProfile.js';
import { validateEmail } from '@/util/validators';
import DynamicDialog from './DynamicDialog.vue';
import { apiUpdateAccount } from '@/resource';
export default {
  components: {
    DynamicDialog
  },
  props: {
    accountVisible: {
      type: Boolean,
      default: false
    },
    existingData: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error(this.$t('subIb.passwordRequired')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('subIb.newPwAccFormat')));
        }
      }
      callback();
    };
    return {
      visible: false,
      permissionList: [],
      accForm: {
        email: '',
        password: '',
        permission: [],
        remark: '',
        subStatus: false
      },
      dialogVisible: false,
      formRules: {
        email: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.email'),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('subIb.emailFormat')),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    accountVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateVisible(bool);
    },
    existingData() {
      this.initialMapping();
    }
  },
  mounted() {
    this.initialMapping();
    this.getPermissionList();
  },
  methods: {
    getPermissionList() {
      this.permissionList = mockPermissionList();
    },
    onClose() {
      this.accForm = getAccountResponse(null);
      this.$emit('close');
    },
    onCancel() {
      this.accForm = getAccountResponse(null);
      this.$emit('cancel');
    },
    fundsPermissionOnChange(value) {
      if (value) {
        this.dialogVisible = true;
      }
    },
    submitAcc() {
      this.$refs.accForm.validate(valid => {
        if (valid) {
          this.submitAccApi();
        }
      });
    },
    submitAccApi() {
      apiUpdateAccount(updateAccountRequest(this.accForm, this.existingData))
        .then(resp => {
          if (resp.data.data == true) {
            this.onClose();
            this.$message({
              message: this.existingData ? this.$t('subIb.modifiedSuccess') : this.$t('subIb.addSuccess'),
              type: 'success'
            });
          } else if (resp.data.code === 0 && !resp.data.data) {
            this.$message({
              message: this.$t('subIb.emailExisting'),
              type: 'error'
            });
          }
        })
        .catch(err => {});
    },
    closeDialog() {
      this.accForm.permission = this.accForm.permission.filter(
        item => item !== defaultVariableMapping['depositWithdrawal']
      );
      this.dialogVisible = false;
    },
    submitDialog() {
      this.dialogVisible = false;
    },
    updateVisible(bool) {
      this.$emit('update:accountVisible', bool);
    },
    initialMapping() {
      if (this.existingData) {
        this.accForm = JSON.parse(JSON.stringify(this.existingData));
      } else {
        this.accForm = getAccountResponse(null);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/settings.scss';

.tooltip_body {
  max-width: 280px;
  white-space: pre-wrap;
}
</style>
