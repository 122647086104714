<template>
  <div id="settings">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('menu.ibProfile') }}</h2>
        </div>
        <div class="action_buttons">
          <el-button :class="showProfile ? 'purple_button' : 'white_button'" @click="proceed('ibProfile')">
            {{ $t('menu.ibProfile') }}
          </el-button>

          <el-button :class="showSub ? 'purple_button' : 'white_button'" @click="proceed('subIb')" v-if="showSubList">
            {{ $t('subIb.title') }}
          </el-button>
        </div>
        <!--main-->
        <div class="main">
          <MainProfile v-if="showProfile" />
          <SubIbListing v-if="showSub" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainProfile from '@/components/profile/MainProfile';
import SubIbListing from '@/components/profile/SubIbListing';

export default {
  components: { MainProfile, SubIbListing },
  data() {
    return {
      showProfile: true,
      showSub: false
    };
  },
  computed: {
    showSubList() {
      return !this.$store.state.common.isSubUser && this.$store.state.common.subUserSwitch;
    }
  },
  methods: {
    proceed(item) {
      if (item == 'ibProfile') {
        this.showProfile = true;
        this.showSub = false;
      } else {
        this.showProfile = false;
        this.showSub = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/settings.scss';

.action_buttons {
  /deep/ .el-button {
    min-width: 182px;
  }
}
</style>
