<template>
  <el-dialog
    id="historyDialog"
    :visible.sync="visible"
    @close="onClose"
    :close-on-click-modal="true"
    destroy-on-close
    width="90%"
  >
    <div slot="title">
      <slot name="header">
        <img src="@/assets/uploads/close.png" class="closeImg" alt @click="onClose()" />
      </slot>
      <h4 class="main_title">{{ $t('subIb.historyTitle') }}</h4>
      <div class="main_desc">
        <div>
          {{ `${$t('subIb.updatedTime')}: ${historyTime}` }}
        </div>
        <a class="refresh_button" @click="refreshData()"
          ><em class="el-icon-refresh-right" /><span>{{ $t('subIb.refresh') }}</span></a
        >
      </div>

      <div class="filter_box">
        <div>{{ $t('subIb.chooseAccount') }}</div>
        <el-select
          @visible-change="handleVisibleChange"
          ref="filterAccountSelector"
          @change="filterAccountSelectorOnChange"
          class="showPlaceholder"
          :placeholder="$t('subIb.chooseAccount')"
          v-model="filterAccount"
          data-testid="selectedFilterAccount"
        >
          <el-option ref="filterAccountOptions" class="option_label" :value="0" :label="$t('subIb.all')" /><el-option
            v-for="(item, index) in accountOptions"
            :key="index"
            :label="item.name"
            :value="item.value"
            data-testid="account"
          />
        </el-select>
      </div>
    </div>

    <div id="timeline" ref="scrollContainer">
      <el-timeline>
        <el-timeline-item v-for="(activity, index) in historyListing" :key="index">
          <template v-slot:dot>
            <img class="icon_img" src="@/assets/uploads/TimeIcon.png" />
          </template>
          <template slot:timestamp>
            <div class="custom_timestamp">
              <div>{{ activity.date }}</div>
              <div class="activity_time">{{ activity.time }}</div>
            </div>
          </template>
          <div class="activity_main">
            <div class="activity_title">
              <!-- Content -->
              {{ activity.content }}
            </div>
            <div class="activity_desc">
              {{ activity.desc }}
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
      <div class="history_end">
        <el-divider class="custom_divider" @click="getMoreData" v-if="!maximumList">
          <span class="end_text">
            {{ $t('subIb.viewMore') }}
          </span>
        </el-divider>
        <el-divider class="custom_divider" v-else>
          <span class="end_text">
            {{ $t('subIb.historyEnd') }}
          </span>
        </el-divider>
      </div>
    </div>
  </el-dialog>
</template>

<script>
const defaultFilterAccountOption = 0;
import { apiGetHistory } from '@/resource';
import { getHistoryListingResponse, showErrorMessage } from './SubProfile.js';

export default {
  props: {
    historyVisible: {
      type: Boolean,
      default: false
    },
    subAccountList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      visible: false,
      filterAccount: defaultFilterAccountOption,
      historyListing: [],
      currentPage: 1,
      totalPage: 1,
      shownAll: false,
      historyTime: '',
      pageSize: 50,
      isLoading: false
    };
  },
  watch: {
    historyVisible(bool) {
      this.visible = bool;
      if (bool) {
        this.filterAccount = defaultFilterAccountOption;
        this.loadInitialData();
      }
    },
    visible(bool) {
      this.updateVisible(bool);
    },
    filterAccount() {
      this.loadInitialData();
    }
  },
  computed: {
    accountOptions() {
      return this.subAccountList.map(item => {
        return {
          name: item?.email,
          value: item?.id
        };
      });
    },
    maximumList() {
      return this.currentPage > this.totalPage || this.historyListing.length < 1;
    }
  },
  methods: {
    resetPagination() {
      this.historyListing = [];
      const container = this.$refs.scrollContainer || null;
      container ? (container.scrollTop = 0) : null;
      this.currentPage = 1;
      this.totalPage = 1;
    },
    onClose() {
      this.filterAccount = defaultFilterAccountOption;
      this.resetPagination();
      this.$emit('close');
    },
    updateVisible(bool) {
      this.$emit('update:historyVisible', bool);
    },
    mounted() {
      window.addEventListener('resize', this.resizeOptions);
    },
    refreshData() {
      this.loadInitialData();
      this.$message({
        type: 'success',
        message: this.$t('subIb.refreshTips')
      });
    },
    loadInitialData() {
      this.resetPagination();
      this.queryGetData();
    },
    getMoreData() {
      this.queryGetData();
    },
    filterAccountSelectorOnChange() {
      this.$nextTick(() => {
        // To trigger css ellipsis
        this.$refs.filterAccountSelector.blur();
      });
    },
    async queryGetData() {
      const request = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        filterAccount: this.filterAccount
      };
      if (this.isLoading) return;

      this.isLoading = true;
      await apiGetHistory(request)
        .then(resp => {
          if (resp.data.success) {
            this.historyListing = this.historyListing.concat(getHistoryListingResponse(resp.data.rows));
            this.currentPage++;
            this.totalPage = Math.ceil(resp.data?.total / this.pageSize);
            this.setTime();
          }
        })
        .catch(err => {
          console.log(err);
          showErrorMessage();
        });
      this.isLoading = false;
    },
    setTime() {
      const moment = require('moment');
      const currentDateTime = moment();
      this.historyTime = currentDateTime.format('YYYY-MM-DD HH:mm:ss');
    },
    filterAccountSelectorOnChange() {
      this.$nextTick(() => {
        // To trigger css ellipsis
        this.$refs.filterAccountSelector.blur();
      });
    },
    handleVisibleChange(visible) {
      if (visible) {
        this.resizeOptions();
      }
    },
    resizeOptions() {
      this.$nextTick(() => {
        const elSelect = document.querySelector('.el-select-dropdown');
        const computedStyle = window.getComputedStyle(elSelect);
        const minWidth = computedStyle.getPropertyValue('min-width');
        elSelect.style.maxWidth = minWidth;
        const elInput = document.querySelector('.el-input__inner');
        console.log(computedStyle.getPropertyValue('max-width'));
        console.log(minWidth);
        const rect = elInput.getBoundingClientRect();
        console.log(rect.x);
        elSelect.style.setProperty('left', `${rect.x}px`, 'important');
      });
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeOptions);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/settings.scss';

.icon_img {
  width: 15px;
  margin-left: -2px;
  padding-top: 5px;

  @media (max-width: 780px) {
    padding-top: 10px;
  }
}

.custom-option {
  max-width: 320px;
  padding-right: 0px !important;
}

/deep/ .el-select {
  display: block;
  .el-input__inner {
    padding-right: 34px;
    text-overflow: ellipsis;
  }
}

.activity_time {
  text-align: right;
}
</style>
