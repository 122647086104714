<template>
  <el-dialog
    id="passwordDialog"
    :visible.sync="visible"
    @close="onClose"
    :close-on-click-modal="true"
    destroy-on-close
    width="50%"
  >
    <div slot="title">
      <slot name="header">
        <h4 class="main_title">{{ $t('subIb.editPassword') }}</h4>
        <img src="@/assets/uploads/close.png" class="closeImg" alt @click="onClose" />
      </slot>
      <div class="title_slot"></div>
    </div>
    <div class="dialog_body">
      <el-form :model="accForm" ref="accForm" :rules="formRules" autocomplete="off" label-position="top">
        <el-form-item prop="password">
          <template slot="label">
            <div class="label_class">
              <div>{{ $t('subIb.resetPassword') }}</div>

              <el-tooltip placement="top" popperClass="popper" :offset="100">
                <div slot="content" class="tooltip_body" v-html="$t('subIb.resetPasswordTooltip')"></div>
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </div>
          </template>
          <el-input v-model="accForm.password" class="password" show-password autocomplete="new-password" clearable />
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" class="btn_submit">
      <el-button class="white_button" @click="onClose()">
        {{ $t('subIb.cancel') }}
      </el-button>
      <el-button class="purple_button" @click="submitAcc()">
        {{ $t('subIb.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { apiUpdatePassword } from '@/resource';
import { updatePasswordRequest, showErrorMessage } from './SubProfile.js';
export default {
  props: {
    existingData: {
      type: Object,
      default: null
    },
    passwordVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error(this.$t('subIb.passwordRequired')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('subIb.newPwAccFormat')));
        }
      }
      callback();
    };
    return {
      accForm: {
        password: ''
      },
      formRules: {
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: 'blur'
          }
        ]
      },
      visible: false
    };
  },

  watch: {
    passwordVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateVisible(bool);
    }
  },
  methods: {
    onClose() {
      this.accForm.password = '';
      this.$emit('cancel');
    },
    updateVisible(bool) {
      this.$emit('update:passwordVisible', bool);
    },
    submitAcc() {
      this.$refs.accForm.validate(valid => {
        if (valid) {
          apiUpdatePassword(updatePasswordRequest(this.accForm, this.existingData))
            .then(resp => {
              if (resp.data.data) {
                this.$emit('close');
                this.$message({
                  message: this.$t('subIb.passwordSuccess'),
                  type: 'success'
                });
              }
            })
            .catch(err => {
              showErrorMessage();
            });
        }
      });
      this.accForm.password = '';
      this.$emit('submit');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/settings.scss';
.tooltip_body {
  max-width: 280px;
  white-space: pre-wrap;
}
</style>
