<template>
  <div class="main_profile" id="mainProfile">
    <div class="info">
      <ul>
        <li class="info_title">{{ $t('settings.name') }}</li>
        <li class="info_ans">{{ profileData.userName }}</li>
      </ul>

      <ul>
        <li class="info_title">{{ $t('settings.emailAdd') }}</li>
        <li class="info_ans">{{ profileData.email }}</li>
      </ul>

      <ul>
        <li class="info_title">{{ $t('settings.phone') }}</li>
        <li class="info_ans">{{ profileData.phone }}</li>
      </ul>

      <ul>
        <li class="info_title">{{ $t('settings.address') }}</li>
        <li class="info_ans">{{ profileData.address }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      profileData: {
        userName: '',
        email: '',
        phone: '',
        address: '',
        createTime: null
      }
    };
  },
  mounted() {
    if (this.$store.state.common.isSubUser) {
      this.profileData = {
        userName: '***',
        email: '***',
        phone: '***',
        address: '***',
        createTime: '***'
      };
    } else {
      this.profileData = {
        userName: this.$store.state.common.userName,
        email: this.$store.state.common.email,
        phone: this.$store.state.common.phone,
        address: this.$store.state.common.address,
        createTime: moment(this.$store.state.common.createTime).format('DD/MM/YYYY')
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/settings.scss';

.info_ans {
  word-break: keep-all;
  overflow: visible;
}
</style>
