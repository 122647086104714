<template>
  <div class="main_info" id="accListing">
    <div class="top_buttons">
      <div>{{ $t('subIb.accountNote') }}</div>
      <div class="buttons_main">
        <el-button class="green_button" @click="createAcc()">
          {{ $t('subIb.subCreateAcc') }}
        </el-button>
        <el-button class="white_button" @click="readHistory()">
          {{ $t('subIb.historyTitle') }}
        </el-button>
      </div>
    </div>
    <div>
      <el-table ref="subTable" :data="subAccountList">
        <el-table-column type="index" width="80" :label="$t('subIb.no')"></el-table-column>

        <el-table-column prop="email" :label="$t('subIb.account')">
          <template slot-scope="scope">
            <div class="copy_main">
              <span>{{ scope.row.email }}</span>
              <el-button
                class="copy_icon"
                @click="copyToClipboard(scope.row.email)"
                icon="el-icon-copy-document "
              ></el-button>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="permissionString" :label="$t('subIb.permission')" min-width="100">
          <template slot-scope="scope">
            <div class="text_left">
              {{ scope.row.permissionString.toString() }}
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('subIb.remark')" class="remark">
          <template slot-scope="scope">
            <el-tooltip
              class="text_left text_overflow"
              v-if="scope.row.remark && scope.row.remark.length > 0"
              placement="top"
            >
              <template #content
                ><div>
                  {{ scope.row.remark }}
                </div></template
              >
              <div class="remark">{{ scope.row.remark }}</div>
            </el-tooltip>
            <div v-else>-</div>
          </template>
        </el-table-column>

        <el-table-column prop="status" :label="$t('subIb.status')">
          <template slot-scope="scope">
            <el-switch
              @change="updateEnable(scope.row)"
              active-color="#41ba92"
              :value="scope.row.subStatus"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="action" :label="$t('subIb.setting')" min-width="80">
          <span slot-scope="scope">
            <el-dropdown @command="handleDropdownClick">
              <span>
                <i style="font-size: 20px" class="setting_icon el-icon-setting"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{
                    action: editVar,
                    item: Object.assign(scope.row)
                  }"
                  icon="el-icon-s-operation"
                  >{{ $t('subIb.editPassword') }}</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{
                    action: updateVar,
                    item: Object.assign(scope.row)
                  }"
                  icon="el-icon-document"
                  >{{ $t('subIb.setting') }}</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{
                    action: deleteVar,
                    item: Object.assign(scope.row)
                  }"
                  icon="el-icon-delete"
                  >{{ $t('subIb.delete') }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </el-table-column>
      </el-table>
    </div>
    <SubIbHistoryDialog
      :historyVisible.sync="historyVisible"
      @close="historyVisible = false"
      :subAccountList="allSubAccountList"
      ref="historyDialog"
    />
    <AccountDialog
      :existingData="actionData?.data ? actionData.data : null"
      :accountVisible.sync="accountVisible"
      @cancel="cancelAccount"
      @close="closeAccount"
    />
    <DynamicDialog
      :dialogDesc="$t('subIb.deleteDesc')"
      dialogTitle=""
      :dialogVisible.sync="dialogVisible"
      @submit="deleteAccount"
      @close="cancelDelete"
    />
    <PasswordDialog
      :existingData="actionData?.data ? actionData.data : null"
      :passwordVisible.sync="passwordVisible"
      @close="updatePassword"
      @cancel="cancelPassword"
    />
  </div>
</template>

<script>
const VALUES = {
  update: 'update',
  delete: 'delete',
  edit: 'edit'
};
import AccountDialog from './AccountDialog.vue';
import PasswordDialog from './PasswordDialog.vue';
import SubIbHistoryDialog from './SubIbHistoryDialog.vue';
import { getAccountListingResponse, deleteRequest, showErrorMessage, updateEnableRequest } from './SubProfile.js';
import DynamicDialog from './DynamicDialog.vue';
import { apiGetSubAccounts, apiEnableDeleteAccount } from '@/resource';

export default {
  components: {
    AccountDialog,
    SubIbHistoryDialog,
    DynamicDialog,
    PasswordDialog
  },
  data() {
    return {
      accountVisible: false,
      historyVisible: false,
      actionData: null,
      usedAction: null,
      updateVar: VALUES['update'],
      deleteVar: VALUES['delete'],
      editVar: VALUES['edit'],
      subAccountList: [],
      allSubAccountList: [],
      dialogVisible: false,
      passwordVisible: false
    };
  },
  mounted() {
    this.getSubAccList();
  },
  methods: {
    createAcc() {
      if (this.subAccountList?.length >= 10) {
        this.$message({
          message: this.$t('subIb.accWarning'),
          type: 'error'
        });
        return;
      }
      this.actionData = null;
      this.accountVisible = true;
    },
    readHistory() {
      this.historyVisible = true;
      this.$refs.historyDialog.loadInitialData();
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$message({
            message: this.$t('subIb.copied', { value: text }),
            type: 'success'
          });
        })
        .catch(err => {});
    },
    handleDropdownClick(command) {
      if (command.action == VALUES['update']) {
        this.actionData = {
          action: VALUES['update'],
          data: command.item
        };
        this.accountVisible = true;
      } else if (command.action == VALUES['delete']) {
        this.actionData = {
          action: VALUES['delete'],
          data: command.item
        };
        this.dialogVisible = true;
      } else if (command.action == VALUES['edit']) {
        this.actionData = {
          action: VALUES['edit'],
          data: command.item
        };
        this.passwordVisible = true;
      }
    },
    cancelAccount() {
      this.actionData = null;
      this.accountVisible = false;
    },
    cancelDelete() {
      this.actionData = null;
      this.dialogVisible = false;
    },
    cancelPassword() {
      this.actionData = null;
      this.passwordVisible = false;
    },
    closeAccount() {
      this.actionData = null;
      this.accountVisible = false;
      this.getSubAccList();
    },
    deleteAccount() {
      if (!this.actionData) return;

      if (this.actionData.action == VALUES['delete']) {
        apiEnableDeleteAccount(deleteRequest(this.actionData?.data))
          .then(resp => {
            if (resp.data.data) {
              this.$message({
                message: this.$t('subIb.deleteSuccess'),
                type: 'success'
              });

              this.getSubAccList();
            }
          })
          .catch(err => {
            showErrorMessage();
          });
      }
      this.dialogVisible = false;
      this.actionData = null;
    },
    updatePassword() {
      this.actionData = null;
      this.passwordVisible = false;
      this.getSubAccList();
    },
    updateEnable(item) {
      apiEnableDeleteAccount(updateEnableRequest(item))
        .then(resp => {
          if (resp.data.code == 0) {
            this.$message({
              message: item.subStatus ? this.$t('subIb.disabledSuccess') : this.$t('subIb.enabledSuccess'),
              type: 'success'
            });
            this.getSubAccList();
          }
        })
        .catch(err => {
          showErrorMessage();
        });
    },
    getSubAccList() {
      apiGetSubAccounts()
        .then(resp => {
          if (resp.data.code == 0) {
            this.allSubAccountList = getAccountListingResponse(resp.data.data);
            this.subAccountList = this.allSubAccountList.filter(item => item.isDel == false);
          }
        })
        .catch(err => {
          errorMessage(this.$t('responseMsg.500'));
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/settings.scss';

.copy_icon {
  font-size: 20px;
}

.buttons_main {
  /deep/ .el-button {
    height: 38px;
  }
}

/deep/ .el-table th .cell {
  padding-top: 20px;
  padding-bottom: 20px;
}

.icon_class {
  width: 18px;
}

.email_main {
  width: 50px;
}
</style>
